import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import React from "react"
import options from "./options"
import "./styles.scss"

const RichText = ({ content }) => {
  if (!content) {
    return null
  }
  return <div>{documentToReactComponents(content.json, options)}</div>
}

export { RichText }
