import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import React from "react"

/* istanbul ignore next */
/* eslint-disable */
export default {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <p className="richTextElement">{children}</p>
    ),
    [BLOCKS.HEADING_1]: (_, children) => (
      <h1 className="richTextElement">{children}</h1>
    ),
    [BLOCKS.HEADING_2]: (_, children) => (
      <h2 className="richTextElement">{children}</h2>
    ),
    [BLOCKS.HEADING_3]: (_, children) => (
      <h3 className="richTextElement">{children}</h3>
    ),
    [BLOCKS.HEADING_4]: (_, children) => (
      <h4 className="richTextElement">{children}</h4>
    ),
    [BLOCKS.HEADING_5]: (_, children) => (
      <h5 className="richTextElement">{children}</h5>
    ),
    [BLOCKS.HEADING_6]: (_, children) => (
      <h6 className="richTextElement">{children}</h6>
    ),
    [BLOCKS.LIST_ITEM]: (_, children) => (
      <li className="richTextElement">{children}</li>
    ),
    [INLINES.HYPERLINK]: ({ data }, children) => (
      <a
        href={data.uri}
        target="_blank"
        rel={`noopener noreferrer${
          data.uri.includes("edeklarera") || data.uri.includes("sheety")
            ? ""
            : " nofollow"
        }`}
      >
        {children}
      </a>
    ),
  },
}
